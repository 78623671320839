import request from '../utlis/requestLbp.js';

export function GetGuanwang() {
	return request.post('/api/page/getguanwang')
}

export function GetAccountList() {
	return request.post('/api/page/getaccountlist')
}


export function GetProstylelist() {
	return request.post('/api/page/getprostylelist')
}

//案例列表请求
export function GetAllProList() {
	//console.log(id)
	return request.post('/api/page/getallproductlist' )
}

export function GetProList(id) {
	//console.log(id)
	return request.post('/api/page/getproductbystylelist' ,{id})
}

export function GetProductInfo(id) {
	return request.post('/api/page/getproductinfobyid' ,{id})
}
