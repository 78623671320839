<template>
	<div class="navigationBox">
		<div class="navigation">
			<div class="IconImg" @click="homePage">
				<img src="@/assets/navAndBottom/icon.png" alt="">
			</div>
			<div class="center">
				<div class="centerItem" v-for="item,index in navigation" :key="index">
					<div class="centerItemBox" @click="Jump(item.path)">{{item.name}}</div>
					<div class="centerI" v-if="item.secondary">
						<div v-for="i,d in item.secondary" :key="d" @click="Jump(i.path)">{{i.name}}</div>
					</div>
				</div>
			</div>
			<!-- <div class="phone">
				<img src="@/assets/navAndBottom/phone.png" alt="">
			</div> -->
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				navigation: [{
					name: 'Home',
					path: "/"
				}, 
				{
					name: 'About Us',
					path: "/about",
					// secondary: [{
					// 	name: '企业简介',
					// 	path: "/about/introduce"
					// }, {
					// 	name: '企业历程',
					// 	path: "/about/course"
					// }, {
					// 	name: '资质荣誉',
					// 	path: "/about/honor"
					// }, {
					// 	name: '企业文化',
					// 	path: "/about/culture"
					// }]
				}, 
				// {
				// 	name: 'Products',
				// 	path: "/case/PopularScience",
				// 	secondary: [{
				// 		name: '科普馆',
				// 		path: "/case/PopularScience"
				// 	}, {
				// 		name: '城市馆',
				// 		path: "/case/City"
				// 	}, {
				// 		name: '文博馆',
				// 		path: "/case/CultureExpo"
				// 	}, {
				// 		name: '文旅馆',
				// 		path: "/case/CulturalTourism"
				// 	}]
				// },
				// {
				// 	name: '技术与服务',
				// 	path: "/Technology/medium",
				// 	secondary: [{
				// 		name: '新媒体技术',
				// 		path: "/Technology/medium"
				// 	}, {
				// 		name: 'Mix&Match',
				// 		path: "/Technology/MixMatch"
				// 	}, {
				// 		name: '集成总包一体化',
				// 		path: "/Technology/integrationView"
				// 	}]
				// }, 
				// {
				// 	name: '新闻中心',
				// 	path: "/NewsSection"
				// }, 
				{
					name: "Contact",
					path: "/map"
				}
				]
			}
		},
		mounted() {},
		methods: {
			homePage() {
				if (this.$route.path != '/') {
					this.$router.push('/')
				}
			},
			Jump(data) {
				if (this.$route.path != data) {
					if (data == '/map') {
						window.scrollTo({
							top: document.body.scrollHeight,
							behavior: 'smooth' // 平滑滚动
						});
					}else{
						this.$router.push(data)
					}
					
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.navigationBox {
		width: 100%;
		border-bottom: 1px #ddd solid;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 99999;
	}

	.navigation {
		width: 1400px;
		height: 100px;
		display: flex;
		align-items: center;
		margin: 0 auto;

		.IconImg {
			cursor: pointer;
			/* 将鼠标样式设置为手型 */
		}

		.center {
			display: flex;
			margin-left: 350px;
			cursor: pointer;
			/* 将鼠标样式设置为手型 */

			.centerItem {
				width: 120px;
				text-align: center;

				.centerItemBox {
					height: 56px;
					line-height: 56px;
					background-color: #fff;

					&:hover {
						color: #BF2032;
					}
				}

				.centerI {
					background-color: #fff;
					display: none;
					position: absolute;

					div {
						width: 120px;
						height: 56px;
						line-height: 56px;
						background-color: #fff;

						&:hover {
							color: #BF2032;
						}
					}
				}

				&:hover {
					.centerItemBox {
						// border-bottom: 4px solid #BF2032;
						// transition: 1s;
					}

					.centerI {
						height: 56px;
						display: block;
					}
				}
			}
		}
	}
</style>
