<template>
	<div class="home">
		<CarouselView></CarouselView>
		<iconView></iconView>
		<AboutUsView></AboutUsView>
		<!-- <img class="full" src="../assets/home/HelloAge.png" alt="">
		<img class="full" src="../assets/home/interaction.png" alt=""> -->
	</div>
</template>

<script>
	// @ is an alias to /src
	import CarouselView from "../components/Carousel/CarouselView.vue";
	import iconView from '../components/Carousel/iconView.vue'
	import AboutUsView from '../views/AboutUsView.vue'
	export default {
		name: "HomeViewNew",
		components: {
			CarouselView,
			iconView,
			AboutUsView
		},
	};
</script>
<style lang="scss">
	.home {
		.full {
			width: 100%;
			margin: 0.2rem auto;
		}
		
	}
</style>
