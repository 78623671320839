// src/directives/noContextMenu.js
export default {
    beforeMount(el) {
      const preventContextMenu = (e) => {
        e.preventDefault();
      };
      
      el.addEventListener('contextmenu', preventContextMenu);
      el._preventContextMenu = preventContextMenu; // 将引用保存到元素上，便于移除
    },
    unmounted(el) {
      el.removeEventListener('contextmenu', el._preventContextMenu);
    },
  };
  