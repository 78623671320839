<template>
	<div class="about">
        <div class="header">
			<h1>ABOUT US</h1>
		</div>
		<div class="content">
			<div class="container">
				<article class="text-content">
					<h2>{{about.title}}</h2>
				<!-- 首页显示的内容 -->
					<div v-if="isHomePage">
						<p>{{ about.introList[0] }}</p> <!-- 只显示第一段文字 -->
						<button class="more" @click="Jump('/about')">MORE</button>
					</div>

					<!-- about 页面显示的内容 -->
					<div v-else-if="isAboutPage">
						<div v-for="(intro, index) in about.introList" :key="index">
							<p>{{ intro }}</p> <!-- 显示所有文字 -->
						</div>
					</div>
					<!-- <div v-for="intro,index in about.introList" :key="index">
						<p>{{intro}}</p>
					</div>					
				-->
				</article>
				<div class="image-content">
					<img :src= about.aboutimg alt="Machine Tool">
				</div>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
    import {
		overall
	} from '../api/overall.js'
	export default {
		data() {
			return {
				about:{},
				isHomePage: false, // 默认值，根据实际情况设置
				isAboutPage: false, // 默认值，根据实际情况设置
			}
		},
		created() {
			this.TopImage()
			//console.log(Window.arr);
		},
		mounted() {
			overall()
				.then(res => {
					//console.log(res.data.about);
					this.about = res.data.about
				}),
			// 假设通过路由或其他方法来设置页面标识
			this.setPageType();
			// this.introduce = Window.arr.introduce
		},
		methods: {
			
			TopImage() {
				//console.log(this.$route.path == '/about/introduce');
				if (this.$route.path == '/about/introduce') {
					this.top = 'introduceCourse.png'
				} else if (this.$route.path == '/about/course') {
					this.top = 'introduceCourse.png'
				} else if (this.$route.path == '/about/honor') {
					this.top = 'honor.png'
				} else if (this.$route.path == '/about/culture') {
					this.top = 'culture.png'
				} else {
					this.top = 'introduceCourse.png'
				}
			},
			Jump(data) {
				if (this.$route.path != data) {
					this.$router.push(data)
				}
			},
			setPageType() {
      // 这里需要根据实际情况来判断是否是首页或者 about 页面
      // 比如通过路由信息来判断
			const path = this.$route.path; // Vue Router 示例
				//console.log("path:",path);
			this.isHomePage = path === '/';
			this.isAboutPage = path === '/about';
			}
		},
		// watch: {
		// 	'$route'(to) {
		// 		if (to.path == '/about/introduce') {
		// 			this.top = 'introduceCourse.png'
		// 		} else if (to.path == '/about/course') {
		// 			this.top = 'introduceCourse.png'
		// 		} else if (to.path == '/about/honor') {
		// 			this.top = 'honor.png'
		// 		} else if (to.path == '/about/culture') {
		// 			this.top = 'culture.png'
		// 		} else {
		// 			this.top = 'introduceCourse.png'
		// 		}

		// 		console.log(to.path);
		// 	}
		// }
	}
</script>

<style lang="scss" scoped>
	.about {
		margin: 0.5rem auto;
		//border: #004FC5 solid 1px;
		
		.header{
			text-align: center;			
			font-size: 0.4rem;
			color: #cc4e26;
			padding: 20px 0;
			border-bottom: #cc4e26 solid 2px;
		}


		.content {
			//@functionborder: #1d41f7 solid 1px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0.3rem;
			//border: #cc4e26 solid 1px;
			.container {
				display: flex;
				flex-direction: row;
				max-width: 1200px;
			//	height: 550px;
				margin: auto;
				gap: 30px;
				.text-content {
					flex-basis: 50%; 
					padding-right: 30px;
					height: 100%;
					display: flex;
					flex-direction: column;

					h2 {
						// margin-top: 10px;
						// margin-bottom: 10px;
						margin:  0.2rem auto;
						font-size: 0.4rem;
						flex-basis: 10%; 
					}

					p {
						margin:0.3rem auto;
						font-size: 0.3rem;
						line-height: 1.5;
						flex-basis: 90%; 
						text-overflow: ellipsis; /* 显示省略号 */
					}
					.more {
						background-color: #f6a828;
						color: white;
						padding: 10px 20px;
						//border-radius: 5px;
						cursor: pointer;
					}
				}

				.image-content 
				{
					flex-basis: 50%; 
				//	border: #cc4e26 solid 1px;
					display: flex;
					align-content: center;

					img {
						width: 100%;
						//height: auto;
					}

				}

			}
		}
    

	}
</style>
