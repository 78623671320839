<template>
	<div class="case" >
		<div class="icon">
			<div  class="iconBox">
				<div v-for="item in icon" :key="item.name" class="IconImgBox" 
					@click="switchElementList(item.id)" @mousemove="switchElementList(item.id)"  
					:class="{'light': item.id === prosytleid}" >
					<img :src="item.img" alt=""  >
					<p class="Chinese">{{item.name}}</p>
					<!-- <img :src="require('../../assets/home/icon/'+item.url)" alt=""> -->
					<!-- <p class="English">{{item.EnglishName}}</p> -->
				</div>	
			</div>					
		</div>
		<div class="caseTitle">
			<p class="">Product List</p>
		</div>	
		<div class="caseBottom">
			<div class="exhibition">
				<div class="exhibitionBox">
					<div class="elementList" v-for="item in productList" :key="item.id" @click="Jump(item)">
						<div class="elementListImage"><img :src="item.img" alt=""></div>
						<div class="elementListTitle">{{item.name}}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {GetProstylelist,GetProList,GetAllProList} from '../../api/LBPapi.js';   
	export default {
		data() {
			return {
				icon: [],
				prosytleid: "",
				proid: "",
				productList:[]
				// icon: [{
				// 	url: "City.png",
				// 	path: "/case/City",
				// 	name: "城市展馆",
				// 	EnglishName: 'City Hall'
				// }, {
				// 	url: "Cultural.png",
				// 	path: "/case/CultureExpo",
				// 	name: "文博展馆",
				// 	EnglishName: 'Cultural Hall'
				// }, {
				// 	url: "Secience.png",
				// 	path: "/case/PopularScience",
				// 	name: "科普展馆",
				// 	EnglishName: 'Secience Hall'
				// }, {
				// 	url: "Culture.png",
				// 	path: "/case/CulturalTourism",
				// 	name: "文旅展馆",
				// 	EnglishName: 'Culture & Tourism'
				// }]
			}
		},
		mounted() {
			//获取类型
			GetProstylelist()
				.then(res => {
					// console.log("------------------------------------")
					// console.log(res.data.data[0])
					this.icon = res.data.data
			}),
			GetAllProList().then(res => {
				// console.log("------------------------------------")
				// console.log(res.data.data)
				this.productList = res.data.data
			})
			
		},
		methods: {
			switchElementList(data) {
				if (this.prosytleid != data) {
					this.prosytleid = data
					GetProList(data)
						.then(res => {
							//console.log(res.data.data)
							this.productList = res.data.data
						})
				}
			},
			Jump(data) {
				//this.$router.push('/product?id='+data.id)
				// 存储 ID 到 sessionStorage
				//sessionStorage.setItem('productId', data.id);
				//this.$router.push('/product');
				this.$store.commit('setProductId', data.id);
				this.$router.push('/product');
			},
			
		},
		watch:{

		}
	
	}
</script>

<style lang="scss" scoped>
    //图片按钮部分
	// .icon {
	// 	width: 65vw;
	// 	height: 4rem;
	// 	display: flex;
	// 	align-items: center;
	// 	margin: auto;		
	// 	// border: #BF2133 1px solid;
    //     //机器类型按钮列表
	// 	.iconBox{
	// 		width: 62vw;
	// 		height: 3.8rem;
	// 		margin: auto;
	// 		overflow: hidden;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: space-around;
	// 		// border: #2126bf 1px solid;
            
	// 		//点亮效果
	// 		.light{
	// 			//border: #BF2133 1px solid;
	// 			//transform: translateY(-2px); /* 上升效果 */
	// 			background-color: #afafaf;

	// 			img {
	// 				width: 1.5rem;
	// 				//transition: 1s;
	// 			}
				
	// 			.Chinese {
	// 				background-color:#7DAF32;
	// 				color: #ffffff;
	// 				font-size: 0.26rem;
	// 			}
	// 		}

	// 		.IconImgBox {
	// 		width: 3.5rem;
	// 		height: 3.5rem;
	// 		display: flex;
	// 		flex-direction: column;
	// 		justify-content: center; /* 主轴上的居中对齐 */
	// 		align-items: center;/* 侧轴上的居中对齐 */
	// 		text-align: center;
	// 		font-size: 16px;
	// 		cursor: pointer;
	// 		margin: auto;
	// 		//border: #2126bf 1px solid;
			
	// 		&:hover {
	// 			//border: #BF2133 1px solid;
	// 			transform: 1; /* 上升效果 */
	// 			background-color: #afafaf;

	// 			img {
	// 				width: 1.5rem;
	// 				transition: 1s;
	// 			}
				
	// 			.Chinese {
	// 				background-color:#7DAF32;
	// 				color: #ffffff;
	// 				font-size: 18px;
	// 			}
	// 		}


	// 		:not(:hover){
    //             /* 将鼠标样式设置为手型 */
	// 		img {
	// 			width: 1.2rem;
	// 			object-fit: contain;
	// 		}

	// 		.Chinese {
	// 			color: #000000;
	// 			font-size: 16px;
	// 		}
	// 		}

			
	// 	}
            
	// 	}

		
        
	// }
	.icon {
    width: 100vw;
    height: 5rem;
    display: flex;
    align-items: center;
    margin: auto;
}

.iconBox {
    width: 93vw;
    height: 4.8rem;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.IconImgBox {
    width: 8rem; /* 放大图标的宽度 */
    height: 4.5rem; /* 放大图标的高度 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px; /* 缩小文字大小 */
    cursor: pointer;
    margin: auto;

    &:hover {
        transform: translateY(-2px); /* 上升效果 */
        background-color: #afafaf;

        img {
            width: 110%; /* 放大悬停时的图片尺寸 */
            transition: 0.5s; /* 更改过渡时间 */
        }

        .Chinese {
            background-color: #7DAF32;
            color: #ffffff;
            font-size: 14px; /* 悬停时的文字大小 */
        }
    }

    &:not(:hover) {
		//border: 1px solid #d43f44;
        img {
            width: 95%; /* 默认状态下的图片尺寸 */
            object-fit: contain;
			//border: 1px solid #2e56e6;
        }

        .Chinese {
            color: #000000;
            font-size: 14px; /* 默认状态下的文字大小 */
        }
    }
}

.light {
    background-color: #afafaf;

    img {
        width: 1.8rem; /* 点亮状态的图片尺寸 */
    }

    .Chinese {
        background-color: #7DAF32;
        color: #ffffff;
        font-size: 14px; /* 点亮状态的文字大小 */
    }
}


	.caseTitle{
		margin: 0.4rem 0;			
		border-bottom: 2px solid black; /* 横线的颜色和宽度 */

		p{
			margin-left: 0.5rem;
			margin-bottom: 0.1rem;
			font-size: 0.3rem;
			color: black;
			font-weight: 900;
		}
	}

	//产品列表
	.caseBottom {
		display: flex;
		justify-content: center;
		margin: 0.4rem 0;
		//border:  1px solid #BF2133;

		/*.exhibition {
			//border:  1px solid #44f06cc4;
			//width: 8.4rem;
			width: 90%;
			display: flex;
			flex-direction: column;
			align-items: center;
			min-height: 6.5rem;
			background-color: #f5f5f5;

			.exhibitionBox {
				width: 90%;
				display: flex;
				//flex-wrap: wrap;
				flex-wrap: nowrap; //不允许换行 
				overflow-x: auto; // 当内容溢出时启用水平滚动条 
				//border:  1px solid #4744f0c4;
				
                
				.elementList {
					//border:  1px solid #44f06cc4;
					width: 4rem;
					height: 5.3rem;
					//border-bottom: 1px solid #cecece;
					margin: 0.3rem;
					display: flex;
					flex-direction: column;
					align-items: center;
				

					&:hover {
						.elementListImage {
							img {
								width: 4.3rem;
								transition: 1s;
							}
						}

						.elementListTitle {
							color: #BF2133;
						}
					}

					.elementListImage {
						//border:  1px solid #f04466c4;
						width:3.8rem;
						height: 2.8rem;
						overflow: hidden;
						margin: 0.3rem  auto;

						img {
							width: 3.7rem;
						}
					}

					.elementListTitle {
						width: 2.7rem;
						font-size: 0.27rem;
						text-align: center;
						margin: 0.3rem auto;
						font-weight: 900;
					}
				}
			}

			.pagination {
				width: 100%;
				height: 1rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
			}
		}*/
		.exhibition {
    width: 90%;
    display: flex;
    flex-direction: column; /* 列方向布局 */
    align-items: center;
    min-height: 6.5rem;
    background-color: #f5f5f5;
    max-height: 500px; /* 设置最大高度以显示滚动条 */
    overflow-y: auto; /* 超出部分可上下滚动 */
    padding: 10px; /* 添加内边距来美观 */
    
    .exhibitionBox {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 每行3个，您可以根据需要改为4个 */
        grid-gap: 10px; /* 设置间距 */
        width: 100%; /* 要确保宽度为100%以适应网格 */
    }
    
    .elementList {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        
        .elementListImage {
            width: 3.8rem;
            height: 2.8rem;
            overflow: hidden;
            margin: 0.3rem auto;
            img {
                width: 100%; /* 图片宽度填满容器 */
            }
        }
       
        .elementListTitle {
            width: 2.7rem;
            font-size: 0.27rem;
            text-align: center;
            margin: 0.3rem auto;
            font-weight: 900;
        }
    }
}

	}
</style>
