<template>
	<div class="Carousel">
		<el-carousel :interval="5000" height="7rem" arrow="always">
			<el-carousel-item v-for="item in Image" :key="item.url">
				<img :src=" item" alt="" />
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	import {GetGuanwang} from '../../api/LBPapi.js';   
export default {
		data() {
			return {
				Image: []					
			}
		},
		mounted() {
			GetGuanwang()
				.then(res => {
					// console.log("------------------------------------")
					// console.log(res.data)
					this.Image = res.data.data.swipers
				})
				
		},
		methods: {
		},
	}
</script>

<style lang="scss">
	.Carousel {
		img {
			width: 100%;
		}
	}
</style>
