import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		newsStorage: {},
		caseTitle: "",
		productId: null,
	},
	getters: {},
	mutations: {
		storageData(state, data) {
			state.newsStorage = data
		},
		ElementInfoTitle(state, data) {
			state.caseTitle = data
		},
		setProductId(state, id) {
			state.productId = id;
		}
	},
	actions: {
		storage(context, data) {
			context.commit('storageData', data)
		},
		caseTitle(context, data) {
			context.commit('ElementInfoTitle', data)
		}
	},
	modules: {}
})
