// ERP看板数据
import axios from 'axios';
// 创建一个vue的实例
// import Vue from 'vue';

// 创建一个request
// http://10.0.0.12/wtopprd/ws/r/awsp920
var request = axios.create({
	baseURL: "/", //默认的请求域名
	timeout: 5000, //请求超时5000毫秒
})

// // 设置请求次数，请求的间隙
request.defaults.retry = 5;
request.defaults.retryDelay = 1000;


request.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
	var config = err.config;
	//console.log(config, "config");
	// If config does not exist or the retry option is not set, reject
	// 如果配置不存在或未设置重试选项，则拒绝
	if (!config || !config.retry) return Promise.reject(err);

	// Set the variable for keeping track of the retry count
	// 设置用于跟踪重试计数的变量
	config.__retryCount = config.__retryCount || 0;

	// Check if we've maxed out the total number of retries
	// 检查我们是否已达到最大重试次数
	if (config.__retryCount >= config.retry) {
		// Reject with the error
		return Promise.reject(err);
	}

	// Increase the retry count
	// 增加重试次数
	config.__retryCount += 1;

	// Create new promise to handle exponential backoff
	// 创建新的承诺以处理指数回退
	var backoff = new Promise(function(resolve) {
		setTimeout(function() {
			resolve();
		}, config.retryDelay || 1);
	});

	// Return the promise in which recalls axios to retry the request
	// 返回其中调用axios以重试请求的承诺
	return backoff.then(function() {
		return request(config);
	});
});


export default request
