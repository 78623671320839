
<template>
	<div class="bottomBox">
		<div class="container">
			<div class="contact-info">
				<h3>CONTACT US</h3>
				<p>Name：{{name}}</p>
				<p>Phone：{{phone}}</p>
				<!-- <p>Linkedin：{{linyin}}</p> -->
				<p>Email：{{email}}</p>
				<!-- <p>Whatapp：{{whatapp}}</p>
				<p>WeiChat：{{weixin}}</p> -->
				<div class="contact-img">
					<div v-for="item,index in contact" :key="index">
						<a :href="item.url"><img :src="item.image" alt=""></a>          
					</div>
				</div>
			</div>
			<!-- <div class="product-center">
				<h3>PRODUCT CENTER</h3>
				<div>
					<div v-for="item,index in product" :key="index">
						<p></p>
					</div>
				</div>
			</div> -->
		</div>
		<p class="company">Copyright © 2024 XuChang Laiyin Machinery Equipment Co., Ltd  <a href="https://beian.miit.gov.cn" target="_blank"> 豫ICP备2024090571
			号</a></p>
	</div>
</template>

<script>
import {GetGuanwang,GetAccountList} from '../../api/LBPapi.js';   
// import {overall} from '../../api/overall.js';   
export default {
		data() {
			return {
				name:""	,
				phone:"",				
				email:"",
				adress: "",
				intruduces:"",								
				contact:[],	
						
			}
		},
		mounted() {
			GetGuanwang()
				.then(res => {
					// console.log("------------------------------------")
					//console.log(res.data.data)
					this.name =  res.data.data.name
					this.phone = res.data.data.phone
					this.email = res.data.data.email
					this.adress = res.data.data.adress										
					this.intruduces = res.data.data.intruduces
					//this.pageCount = res.data.data.totalpage * 1
				}),
			GetAccountList()
			.then(res => {
				// console.log("------------------------------------")
				console.log(res.data.data)
				this.contact = res.data.data
			})
			// overall()
			// .then(res => {
			// 	//console.log(res.data.contact);
			// 	this.contact = res.data.contact
			// })
				
		},
		methods: {
		},
	}
</script>

<style lang="scss">
	.bottomBox {
		width: 100%;
		height: 4.4rem;
		background: url('@/assets/navAndBottom/bottomBack.png') no-repeat;
		background-size: 100% 360px;
		//background-color: #1c1c1c;
		color: #d2d2d2;
		font-size: 0.2rem;
		overflow: hidden;
		position: relative;
		bottom: 0;

		.container {
			margin: 0.2rem auto;
			width: 100vh;
			//border: #d2d2d2 solid 1px;
			display: flex;
			justify-content: center; /* 水平居中 */
			align-items: center; /* 垂直居中 */
			//justify-content: space-between;
			//height: 100vh;

			.contact-info{
				width: 100%;
				font-size: 16px;
				//border: #c51f1f solid 1px;

				h3{
					color: #ffffff;
					font-size: 23px;
					line-height: 30px;
					margin-bottom: 10px;
					border-bottom: #d2d2d2 solid 1px;
					margin: 5px auto;
				}

				p{
					list-style-type: none;
					margin: 0.1rem;
					padding: 0;
				}

				.contact-img{
					display: flex;
					flex-direction: row;

					img{
						width: 0.3rem;
						margin: 0.15rem ;
					}
				}

			}
			.product-center{

			}

		}



		
		.company {
			// margin-top: 0.5rem;
			text-align: center;
			font-size: 0.2rem;

			a{
				margin-left: 0.2rem;
				font-size: 0.2rem;
				color: #ffffff;
			}
		}
	}
</style>
