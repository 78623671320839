import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/flexible.min.js'
//创建一个自定义指令，统一处理图片和视频的右键禁用。
import noContextMenu from './directives/noContextMenu';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.directive('no-context-menu', noContextMenu);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
